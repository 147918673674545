import * as React from "react"
import Main from "../components/Layout/Main";
import "../styles/pages/contato.scss"
import Breadcrumb from "../components/Layout/Breadcrumb";
import Images from "../theme/images";
import mapStyle from "../theme/mapStyle";
import { compose, withProps } from "recompose";
import DownloadPdf from "../components/DownloadPdf";
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs
} from "react-google-maps";
import axios from "axios";
import Modal from "../components/Modal";
import images from "../theme/images";
import {useState} from "react";
import Seo from "../components/Seo";

const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBjmivqkRid_7Mo3AKzWdMmeELci3no4FY&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={16} defaultCenter={{ lat: -23.22387351459137, lng: -47.52577143606661 }} defaultOptions={{ styles: mapStyle }}>
        {props.isMarkerShown && (
            <Marker
                icon={{
                    url: Images.iconMarker
                }}
                style={{textAlign: 'center', transform: "translate(-50%, -50%)", position: "absolute"}}
                position={{ lat: -23.22387351459137, lng: -47.52577143606661 }}
            />
        )}
    </GoogleMap>
));



const ContatoPage = () => {
    const [showModal, setShowModal] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [showLoading, setShowLoading] = useState(true)

    const maskTelephone = (e) => {
        let value = e.target.value
        value = value.replace(/\D/g, "")
        value = value.replace(/^(\d{2})(\d)/g, "($1) $2")
        value = value.replace(/(\d)(\d{4})$/, "$1-$2")
        e.target.value = value
    }

    const maskZipcode = (e) => {
        let value = e.target.value
        value = value.replace(/\D/g, "")
        if (value.length > 7) {
            value = value.replace(/(\d)(\d{3})$/, "$1-$2")
        }
        e.target.value = value
    }

    const handleForm = (e) => {
        e.preventDefault();
        let form = e.target;

        setShowLoading(true);
        setShowModal(true);
        setHasError(false);

        axios.post(`${process.env.API_URL}/contact`, {
            secret: "Z5mpmSQmVdefh5D#UQCt9P3)G838&~xBL7]z",
            name: form.name.value,
            email: form.email.value,
            telephone: form.telephone.value,
            address: form.address.value,
            number: form.number.value,
            neighborhood: form.neighborhood.value,
            complement: form.complement.value,
            city: form.city.value,
            zipcode: form.zipcode.value,
            country: form.country.value,
            subject: form.subject.value,
            message: form.message.value,
        },{
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'bEI2vUjdqp5nHcwGVaFqD5dC3MNZzeqKadx1TwIL'
            }
        })
        .then(()=> {
            form.reset();
            setHasError(false);
        })
        .catch(()=>setHasError(true))
        .finally(()=> {
            setShowLoading(false)
        })

        if(newsChecked) {
            axios.post(`${process.env.API_URL}/subscriber`, {
                email: form.name.email,
                source: 'CONTATO',
                sendCatalog: false,
                secret: form.name.secret
            })
                .then(response => console.log(response))
                .catch(error => console.log(error))
        }
    }

    let newsChecked = true;
    return (
        <Main>
            <Seo title="Contato" description="Veja aqui como entrar em contato com a gente."/>
            <Breadcrumb title="Contato"/>
            <div className="container mx-auto px-4">
                <p className="text-darkpurple font-bold text-center py-8 md:py-2 md:text-left md:pt-20 md:pb-8">
                    Preencha o formulário de contato e retornaremos em breve.
                </p>
                <div className="xl:grid xl:grid-cols-2 gap-4">
                    <div className="p-12 ribbon-wrapper relative">
                        <div className="ribbon z-20 relative">
                            <div className="relative z-50 p-8">
                                <h1 className="text-2xl font-bold mb-3">Contato</h1>
                                <form name="contactForm" method="POST" onSubmit={e => handleForm(e)}>
                                    <input name="secret" value="8nNb2gNr6NwW!]y>8P)A;6B#%2m]_FG2$E%9" type="hidden"/>
                                    <input
                                        className="my-2 shadow appearance-none border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text" name="name" placeholder="Nome" required />
                                    <div className="md:grid md:gap-4 md:grid-cols-2">
                                        <input
                                            className="my-2 shadow appearance-none border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="email" name="email" placeholder="Email" required />
                                        <input
                                            className="my-2 shadow appearance-none border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" name="telephone" placeholder="Telefone" required pattern="\(\d{2}\)\s*\d{5}-\d{4}"
                                            onChange={e => maskTelephone(e)} onKeyPress={e => maskTelephone(e)} minlength="15" maxlength="15"/>
                                    </div>
                                    <div className="md:flex md:space-x-4">
                                        <input
                                            className="w-full md:w-3/4 my-2 shadow appearance-none border border-light-gray rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" name="address" placeholder="Endereço" required />
                                        <input
                                            className="w-full md:w-1/4 flex-1 my-2 shadow appearance-none border border-light-gray rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" name="number" placeholder="Número" required />
                                    </div>
                                    <div className="md:grid md:gap-4 md:grid-cols-2">
                                        <input
                                            className="my-2 shadow appearance-none border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" name="neighborhood" placeholder="Bairro" required />
                                        <input
                                            className="my-2 shadow appearance-none border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" name="complement" placeholder="Complemento" />
                                    </div>
                                    <div className="md:grid md:gap-4 md:grid-cols-3">
                                        <input
                                            className="my-2 shadow appearance-none border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" name="city" placeholder="Cidade" required />
                                        <input
                                            className="my-2 shadow appearance-none border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" name="zipcode" placeholder="Cep" required
                                            onChange={e => maskZipcode(e)} onKeyPress={e => maskZipcode(e)} minlength="9" maxlength="9"/>
                                        <select
                                            className="my-2 shadow bg-white border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" name="country" required>
                                            <option value="">Estado (selecione)</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espirito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </div>
                                    <select
                                        className="my-2 shadow bg-white border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text" name="subject" required>
                                        <option value="">Assunto (selecione)</option>
                                        <option value="Dúvidas">Dúvidas</option>
                                        <option value="Sugestões">Sugestões</option>
                                        <option value="Elogios">Elogios</option>
                                        <option value="Reclamações">Reclamações</option>
                                        <option value="Trabalhe Conosco">Trabalhe Conosco</option>
                                        <option value="Outros">Outros</option>
                                    </select>
                                    <textarea
                                        className="my-2 shadow appearance-none border border-light-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text" name="message" placeholder="Mensagem" rows="6" required></textarea>
                                    <div className="flex mt-3">
                                        <div className="flex-1 pt-1"><label className="block"><input type="checkbox" name="subscribe" defaultChecked className="mr-2" onChange={() => {newsChecked = !newsChecked}}/>Aceito receber novidades</label></div>
                                        <div className="text-right">
                                            <button type="submit" className="bg-gradient-to-r from-pink to-purple font-bold text-white text-xl border-2 border-white rounded-full pl-6 pr-2 py-1 shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                Enviar
                                                <img src={Images.iconChevronDoubleRight} className="float-right ml-2 pt-0.5" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 sm:p-12 lg:pt-0 2xl:p-32">
                        <div className="mx-2 sm:mx-5 sm:mx-0 border-black border-2 bg-white p-2 sm:p-10 text-darkpurple">
                            <h1 className="text-2xl font-bold mb-2">Atendimento</h1>
                            <p>(15) 3261-2006</p>
                            <p>(15) 3262-5799</p>

                            <h1 className="text-2xl font-bold mt-10 mb-2">Financeiro</h1>
                            <p>(15) 3261-2006</p>

                            <p className="mt-10 break-all">contato@misterbrinque.com.br</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="border-b-4 border-purple">
                    <div className="text-center bg-purple text-white font-bold text-2xl py-2">Como Chegar</div>
                    <MyMapComponent isMarkerShown />
                </div>
                <div className="bg-purple-custom">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <img src={Images.folder} className="md:float-right" alt="" />
                            </div>
                            <div className="flex-1 pt-12 px-12 md:pt-10 lg:px-32">
                                <DownloadPdf sendCatalog/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-6 bg-darkpurple w-full"></div>
            </div>
            {
                showModal && (
                    <Modal>
                        <div className="text-center justify-center">
                            { (!showLoading && !hasError) && (
                                <>
                                    <img src={images.iconSuccess} alt="Sucesso" className="w-20 m-3 m-auto"/>
                                    <h2 className="text-xl font-bold py-4 text-darkpurple">Sucesso</h2>
                                    <p className="text-sm px-8 text-dark-gray">Recebemos seu e-mail e iremos entrar em contato o mais breve possível.</p>
                                </>
                            )}
                            { (!showLoading && hasError) && (
                                <>
                                    <h2 className="text-xl font-bold py-4 text-darkpurple">Opss... ocorreu um erro</h2>
                                    <p className="text-sm px-8 text-dark-gray">Ocorreu um erro ao enviar seu e-mail, por favor utilize outro meio de contato ou envie um e-mail para:<br/><a className="text-purple" href="mailto:contato@misterbrinque.com.br">contato@misterbrinque.com.br</a></p>
                                </>
                            )}
                            { (showLoading) && (
                                <div className="text-center">
                                    <img className="animate-spin w-12 m-auto" src={Images.iconLoading} alt=""/>
                                </div>
                            )}
                            { (!showLoading) && (
                                <button className="mt-10 mb-2 md:mb-0 text-dark-gray px-5 py-2 text-sm shadow-sm font-medium tracking-wider transition-all border rounded-full hover:shadow-lg" onClick={()=>setShowModal(false)}>FECHAR</button>
                            )}
                        </div>
                    </Modal>
                )
            }
        </Main>
    )
}

export default ContatoPage
